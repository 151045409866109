import React from 'react';
import classNames from 'classnames';

import "./Section.scss";

export default function Section({className, fullHeight, darkerBg, children, ...otherProps}) {
  return <section className={classNames("Section", className, {
    "full-height": fullHeight,
    "dark-bg": darkerBg
  })}
    {...otherProps}
  >
    <div className="inner">
      {children}
    </div>
  </section>
}