import React from 'react';
import classNames from 'classnames';

import "./Button.scss";

export default function Button({className, href, text, large, newWindow=false, ...otherProps}) {
  return <a className={classNames("Button", className, {large})}
    href={href} target={newWindow ? "_blank" : ""}
  >
    {text}
  </a>
}