import React from "react"
import Section from "components/Section"

import heroImage from "images/concentrating-in-front-of-monitor.jpg"

import challengeIcon from "images/icons/challenge.png"
import reportIcon from "images/icons/report.png"
import toTheSkyIcon from "images/icons/to-the-sky.png"

import medicalIcon from "images/icons/round/256_pills.png"
import financialIcon from "images/icons/round/256_man-money.png"
import growthIcon from "images/icons/round/256_plant.png"

import mailIcon from "images/icons/round/256_mailbox.png"
import phoneIcon from "images/icons/round/256_iphone.png"

import targetIcon from "images/icons/target.png"
import brainstormingIcon from "images/icons/brainstorming.png"
import successIcon from "images/icons/success.png"

import paperworkIcon from "images/icons/paperwork.png"
import budgetIcon from "images/icons/budget.png"
import partnershipIcon from "images/icons/partnership.png"
import appLookIcon from "images/icons/app-look.png"
import approvedIcon from "images/icons/approved.png"
import serviceIcon from "images/icons/service.png"
import dataAnalysisIcon from "images/icons/data-analysis.png"

import menuLogo from "images/SprinTech-white-logotype.png"

import "./App.scss"
import Button from "../components/Button"
import IconBox from "../components/IconBox"
import calculatorIcon from "../images/icons/round/256_calculator.png"
import magnifyingMoneyIcon from "../images/icons/round/256_magnifier-money.png"
import timeMoneyIcon from "../images/icons/round/256_coin-clock.png"
import mugPaintingIcon from "../images/icons/round/256_mug.png"

const CONTACT_DATA = {
  DEFAULT: {
    email: "hello@sprin.tech",
    phone: "513 504 785",
  },
  marek: {
    email: "marek@sprin.tech",
    phone: "504 024 433",
  },
  mariusz: {
    email: "mariusz@sprin.tech",
    phone: "601 349 063",
  },
  magda: {
    email: "magda@sprin.tech",
    phone: "513 504 785",
  },
}

const user = window.location.pathname.split("/")[1]

let contact = CONTACT_DATA.DEFAULT
if (user && CONTACT_DATA[user]) {
  contact = CONTACT_DATA[user]
}

function App({ long = true }) {
  const heroStyle = {
    backgroundImage: `url(${heroImage})`,
  }

  return (
    <div className="App">
      <div className="menu">
        <div className="logo">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a href="#">
            <img src={menuLogo} alt="SprinTECH logo" />
          </a>
        </div>
        <div className="links">
          <a href="#about-sprintech">Co robimy?</a>
          <a href="#how-we-work">Jak pracujemy?</a>
          {long && (
            <>
              <a href="#pricing">Cennik</a>
              <a href="#case-studies">Przykłady użycia</a>
            </>
          )}
          <a href="#contact">Kontakt</a>
        </div>
      </div>
      <Section id="hero" fullHeight={true} darkerBg={true} style={heroStyle}>
        <h1 className="title">
          Sprin<span className="sprint-colored">TECH</span> Learning
        </h1>
        <h2 className="subtitle">Nauka nigdy nie była łatwiejsza</h2>
        <Button large={true} text="Czym się zajmujemy?" href="#about-sprintech" />
      </Section>
      <Section id="about-sprintech" fullHeight={true}>
        <div className="card">
          <h1>Co robimy?</h1>
          <div className="icons">
            <IconBox iconPath={challengeIcon}>
              <p>
                Tworzymy dla Ciebie sprinty, czyli krótkie, interaktywne aplikacje doskonalące lub
                sprawdzające wiedzę Twojego zespołu
              </p>
            </IconBox>
            <IconBox iconPath={reportIcon}>
              <p>Analizujemy wyniki osiągnięte przez zespół i przedstawiamy szczegółowy raport</p>
            </IconBox>
            <IconBox iconPath={toTheSkyIcon}>
              <p>
                Pokazujemy miejsca, w których należy uzupełnić braki wiedzy, aby zwiększać
                efektywność Twoich pracowników
              </p>
            </IconBox>
          </div>
          <div className="actions">
            <Button
              className="accent"
              large={true}
              text="Wypróbuj sprint!"
              href="https://play.sprin.tech/first-aid"
              newWindow={true}
            />
            <Button large={true} text="Jak pracujemy?" href="#how-we-work" />
          </div>
        </div>
      </Section>
      <Section id="how-we-work" fullHeight={true}>
        <div className="card">
          <h1>Sposób współpracy</h1>
          <div className="work-steps">
            <IconBox
              className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={paperworkIcon}
            >
              <div className="step">1</div>
              <div className="description">Przesyłasz nam materiały do przygotowania Sprintu</div>
            </IconBox>
            <IconBox
              className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={budgetIcon}
            >
              <div className="step">2</div>
              <div className="description">
                Wyliczamy liczbę godzin potrzebnych na przygotowanie Sprintu i ustalmy, na kiedy
                może być gotowy.
              </div>
            </IconBox>
            <IconBox
              className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={partnershipIcon}
            >
              <div className="step">3</div>
              <div className="description">
                Po twojej akceptacji kosztów i terminu przystępujemy do działania.
                <strong>
                  {" "}
                  Przeciętna liczba godzin na stworzenie jednego sprintu to 7-9 godzin!
                </strong>
              </div>
            </IconBox>
            <IconBox
              className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={appLookIcon}
            >
              <div className="step">4</div>
              <div className="description">Przesyłamy do twojej akceptacji gotowy Sprint.</div>
            </IconBox>
            <IconBox
              className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={approvedIcon}
            >
              <div className="step">5</div>
              <div className="description">
                Wprowadzamy ewentualne poprawki i ustalmy termin odpaleniu Sprintu.
              </div>
            </IconBox>
            <IconBox
              className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={serviceIcon}
            >
              <div className="step">6</div>
              <div className="description">
                W momencie działania Sprintu prowadzimy nadzór techniczny. Masz otwartą „gorącą
                linię”!
              </div>
            </IconBox>
            <IconBox
              className="cooperation-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={dataAnalysisIcon}
            >
              <div className="step">7</div>
              <div className="description">
                Po Sprincie przesyłamy do Ciebie raport z analizą odpowiedzi.
              </div>
            </IconBox>
          </div>
          {long && (
            <div className="actions">
              <Button large={true} text="Zobacz cennik" href="#pricing" />
            </div>
          )}
          {!long && (
            <div className="actions">
              <Button large={true} text="Skontaktuj się z nami!" href="#contact" />
            </div>
          )}
        </div>
      </Section>
      {long && (
        <>
          <Section id="pricing" fullHeight={true}>
            <div className="card">
              <h1>Cennik</h1>
              <div className="work-steps">
                <IconBox
                  className="cooperation-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={calculatorIcon}
                >
                  <div className="description">
                    Koszt sprintu szacujemy na podstawie{" "}
                    <strong>materiałów, które nam przekażesz</strong> oraz{" "}
                    <strong>Twoich wymagań graficznych</strong>.
                  </div>
                </IconBox>
                <IconBox
                  className="cooperation-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={magnifyingMoneyIcon}
                >
                  <div className="description">
                    Wyceniamy ile godzin pracy naszego zespołu jest potrzebne, aby dostarczyć pełen
                    pakiet: <strong>ćwiczenia</strong>, <strong>wsparcie techniczne</strong> oraz{" "}
                    <strong>raport z wyników</strong>.
                  </div>
                </IconBox>
                <IconBox
                  className="cooperation-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={timeMoneyIcon}
                >
                  <div className="description">
                    Średnio, Sprint to tylko <strong>7-9 godzin</strong> naszej pracy, wycenianej{" "}
                    <strong>380 zł za godzinę</strong>.
                  </div>
                </IconBox>{" "}
                <IconBox
                  className="cooperation-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={mugPaintingIcon}
                >
                  <div className="description">
                    Jeżeli interesuje Cię{" "}
                    <strong>dostosowanie grafiki sprintu do identyfikacji wizualnej firmy</strong>,
                    do powyższej wyceny dodaj jednorazowy koszt 4-5 godzin naszej pracy.
                  </div>
                </IconBox>
              </div>
              <div className="actions">
                <Button large={true} text="Zobacz przykłady zastosowania" href="#case-studies" />
              </div>
            </div>
          </Section>
          <Section id="case-studies" fullHeight={true}>
            <div className="card">
              <h1>Case studies</h1>
              <p>
                Poniżej znajdziesz przykładowe projekty zrealizowane dla naszych Klientów. Zobacz,
                jakie były ich rezultaty:
              </p>
              <div className="icons">
                <a href="#case-study-pharmaceutical" className="no-style">
                  <IconBox
                    className="case-study-icon-box"
                    position={IconBox.POSITIONS.LEFT}
                    iconPath={medicalIcon}
                  >
                    <h3>Test wiedzy o produkcie</h3>
                    <p>
                      Projekt dla firmy farmaceutycznej, mający na celu weryfikację i uzupełnienie
                      wiedzy przedstawicieli handlowych tej firmy.
                    </p>
                  </IconBox>
                </a>
                <a href="#case-study-financial" className="no-style">
                  <IconBox
                    className="case-study-icon-box"
                    position={IconBox.POSITIONS.LEFT}
                    iconPath={financialIcon}
                  >
                    <h3>Warsztaty rozwojowe</h3>
                    <p>
                      Projekt dla działu handlowego dużej firmy technologicznej, nakierunkowany na
                      rozwój kompetencji zespołu.
                    </p>
                  </IconBox>
                </a>
                {false && (
                  <a href="#case-study-growth" className="no-style">
                    <IconBox
                      className="case-study-icon-box"
                      position={IconBox.POSITIONS.LEFT}
                      iconPath={growthIcon}
                    >
                      <h3>Wzmacnianie nastawienia na rozwój</h3>
                      <p>
                        Projekt ogólnodostępny, skierowany do rodziców. Miał na celu szerzenie
                        wiedzy, w jaki sposób prosto i efektywnie komunikować się, aby rozwijać
                        nastawienie na rozwój.
                      </p>
                    </IconBox>
                  </a>
                )}
                <a href="#case-study-well-being" className="no-style">
                  <IconBox
                    className="case-study-icon-box"
                    position={IconBox.POSITIONS.LEFT}
                    iconPath={growthIcon}
                  >
                    <h3>Projekt well-beingowy</h3>
                    <p>
                      Projekt miał na celu przekazanie i utrwalenie wiedzy wśród pracowników
                      wszystkich oddziałów firmy w Europie na temat profilaktyki raka prostaty.
                    </p>
                  </IconBox>
                </a>
              </div>
              <div className="actions">
                <Button
                  large={true}
                  text="Przejdź do pierwszego case study"
                  href="#case-study-pharmaceutical"
                />
              </div>
            </div>
          </Section>
          <Section id="case-study-pharmaceutical" className="case-study-details" fullHeight={true}>
            <div className="card case-study">
              <div className="info">
                <h4 className="count">Case study 1</h4>
                <h1 className="title">Test wiedzy o produkcie</h1>
              </div>
              <div className="content">
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={targetIcon}
                >
                  <strong>Cel:</strong> Weryfikacja i uzupełnienie wiedzy Przedstawicieli Medycznych
                </IconBox>
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={brainstormingIcon}
                >
                  <strong>Wykonanie:</strong> Cykl sprintów, w których pracowaliśmy w schemacie:
                  <ul>
                    <li>Stworzenie pytań do testów na podstawie przesłanych materiałów</li>
                    <li>Konsultacje i zatwierdzenie testu</li>
                    <li>
                      Przeprowadzanie <strong>testu wiedzy nr 1</strong> wraz z{" "}
                      <strong>raportem</strong> zawierającym obszary dobrze przyswojone i te do
                      poprawy
                    </li>
                    <li>
                      Przeprowadzanie <strong>testu wiedzy nr 2</strong> dla osób, które nie
                      osiągnęły zamierzonego rezultatu (po około tygodniu)
                    </li>
                    <li>
                      Przeprowadzanie <strong>testu wiedzy utrwalającego</strong> przekazaną wiedzę
                      (po około miesiącu)
                    </li>
                  </ul>
                </IconBox>
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={successIcon}
                >
                  <strong>Rezultat:</strong> Znacząca poprawa wyników na przestrzeni kolejnych
                  sprintów:
                  <ul>
                    <li>
                      <strong>25%</strong> - tyle średnio osób nie zdaje testu za pierwszym
                      podejściem
                    </li>
                    <li>
                      <strong>100%</strong> - wszyscy zdają testy za drugim podejściem
                    </li>
                    <li>
                      <strong>od 5% do 35%</strong> - do tylu wzrosła w 3 cyklu liczba osób
                      osiągających za pierwszym podejściem 100% dobrych odpowiedzi
                    </li>
                    <li>
                      <strong>100%</strong> - obecnie wszyscy zaliczają test utrwalający
                    </li>
                    <li>
                      <strong>od 58% do 81%</strong> - do tylu wzrosła w 3 cyklu liczba osób
                      osiągających 100% dobrych odpowiedzi w teście utrwalającym!
                    </li>
                  </ul>
                </IconBox>
              </div>
              <div className="actions">
                <Button
                  className="return"
                  large={true}
                  text="Wróć do listy case studies"
                  href="#case-studies"
                />
                <Button
                  className="accent"
                  large={true}
                  text="Sprawdź sprint"
                  newWindow={true}
                  href="https://go.sprin.tech/popularny-lek"
                />
                <Button
                  large={true}
                  text="Przejdź do następnego case study"
                  href="#case-study-financial"
                />
              </div>
            </div>
          </Section>
          <Section id="case-study-financial" className="case-study-details" fullHeight={true}>
            <div className="card case-study">
              <div className="info">
                <h4 className="count">Case study 2</h4>
                <h1 className="title">Warsztaty rozwojowe</h1>
              </div>
              <div className="content">
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={targetIcon}
                >
                  <strong>Cel:</strong> Wzmocnienie zaangażowania uczestników warsztatu
                </IconBox>
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={brainstormingIcon}
                >
                  <strong>Wykonanie:</strong> 2 dni szkolenia z wykorzystaniem sprintów.
                  <ul>
                    <li>4 prowadzących, 50 uczestników, praca w salach rotacyjnych</li>
                    <li>Zdobyte punkty w sprintach sumowane w indywidualnym rankingu</li>
                    <li>Sprinty zostały wykorzystane do:</li>
                    <ul>
                      <li>preworku</li>
                      <li>wprowadzenia do szkolenia</li>
                      <li>podsumowania bloków tematycznych</li>
                      <li>uzupełnienia luk w omawianej wiedzy</li>
                    </ul>
                  </ul>
                </IconBox>
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={successIcon}
                >
                  <strong>Rezultat:</strong>
                  <ul>
                    <li>
                      <strong>Wysokie zaangażowanie</strong> od pierwszych do ostatnich minut
                      trwania warsztatu
                    </li>
                    <li>
                      <strong>Szybkie korygowanie luk</strong> w przyswojeniu kluczowych informacji
                      i wiedzy przekazywanej podczas warsztatu
                    </li>
                    <li>
                      <strong>98%</strong> uczestników uznało sprinty za bardzo przydatne
                    </li>
                  </ul>
                </IconBox>
              </div>
              <div className="actions">
                <Button
                  className="return"
                  large={true}
                  text="Wróć do listy case studies"
                  href="#case-studies"
                />
                <Button
                  className="accent"
                  large={true}
                  text="Sprawdź sprint"
                  newWindow={true}
                  href="https://go.sprin.tech/sprawdz/77"
                />
                {false && (
                  <Button
                    large={true}
                    text="Przejdź do następnego case study"
                    href="#case-study-growth"
                  />
                )}
                <Button
                  large={true}
                  text="Przejdź do następnego case study"
                  href="#case-study-well-being"
                />
              </div>
            </div>
          </Section>
          {false && (
            <Section id="case-study-growth" className="case-study-details" fullHeight={true}>
              <div className="card case-study">
                <div className="info">
                  <h4 className="count">Case study 3</h4>
                  <h1 className="title">Wzmacnianie nastawienia na rozwój</h1>
                </div>
                <div className="content">
                  <IconBox
                    className="case-study-details-icon-box"
                    position={IconBox.POSITIONS.LEFT}
                    iconPath={targetIcon}
                  >
                    <strong>Cel:</strong> Nauczenie kilku prostych i efektywnych komunikatów
                    rozwijających nastawienie na rozwój
                  </IconBox>
                  <IconBox
                    className="case-study-details-icon-box"
                    position={IconBox.POSITIONS.LEFT}
                    iconPath={brainstormingIcon}
                  >
                    <strong>Wykonanie:</strong> Ogólnodostępny sprint, reklamowany na Facebooku.
                    <ul>
                      <li>Średni czas trwania Sprintu: 6 minut</li>
                      <li>Podzielony na 4 części:</li>
                      <ul>
                        <li>
                          <strong>Test oceniający</strong> poziom nastawienia na rozwój i trwałość
                        </li>
                        <li>
                          <strong>Ćwiczenie wyjaśniające</strong> różnice pomiędzy oboma
                          nastawieniami
                        </li>
                        <li>
                          <strong>Ćwiczenie uczące</strong> komunikatów budujących nastawienie na
                          rozwój
                        </li>
                        <li>
                          <strong>Test sprawdzający</strong> opanowanie wiedzy.
                        </li>
                      </ul>
                    </ul>
                  </IconBox>
                  <IconBox
                    className="case-study-details-icon-box"
                    position={IconBox.POSITIONS.LEFT}
                    iconPath={successIcon}
                  >
                    <strong>Rezultat:</strong>
                    <ul>
                      <li>
                        Około <strong>390</strong> osób wypełniło sprint
                      </li>
                      <li>
                        Test oceniający - około <strong>65%</strong> odpowiedzi wskazywało na
                        nastawienie na trwałość
                      </li>
                      <li>
                        Test sprawdzający - około <strong>67%</strong> poprawnych odpowiedzi
                      </li>
                      <li>Zidentyfikowanie 2 istotnych błędów w budowaniu komunikatów</li>
                    </ul>
                  </IconBox>
                </div>
                <div className="actions">
                  <Button
                    className="return"
                    large={true}
                    text="Wróć do listy case studies"
                    href="#case-studies"
                  />
                  <Button
                    className="accent"
                    large={true}
                    text="Sprawdź sprint"
                    newWindow={true}
                    href="https://swieta.sprin.tech/"
                  />
                  <Button large={true} text="Co dalej?" href="#contact" />
                </div>
              </div>
            </Section>
          )}
          <Section id="case-study-well-being" className="case-study-details" fullHeight={true}>
            <div className="card case-study">
              <div className="info">
                <h4 className="count">Case study 3</h4>
                <h1 className="title">Projekt well-beingowy</h1>
              </div>
              <div className="content">
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={targetIcon}
                >
                  <strong>Cel:</strong> Przekazanie i utrwalenie wiedzy wśród pracowników wszystkich
                  oddziałów firmy w Europie na temat profilaktyki raka prostaty.
                </IconBox>
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={brainstormingIcon}
                >
                  <strong>Wykonanie:</strong>
                  <ul>
                    <li>Średni czas trwania Sprintu: 4 minut</li>
                    <li>Czas trwania akcji: 3 tygodnie</li>
                    <li>Dwie wersje językowe: angielska i polska</li>
                    <li>Pełne dopasowanie grafiki do identyfikacji graficznej klienta.</li>
                  </ul>
                </IconBox>
                <IconBox
                  className="case-study-details-icon-box"
                  position={IconBox.POSITIONS.LEFT}
                  iconPath={successIcon}
                >
                  <strong>Rezultat:</strong>
                  <ul>
                    <li>Ponad 20% pracowników wzięło udział w Sprincie.</li>
                    <li>Zidentyfikowane dwie istotne luki w wiedzy na temat choroby.</li>
                    <li>Ponad 70% trafność w identyfikowaniu symptomów choroby.</li>
                  </ul>
                </IconBox>
              </div>
              <div className="actions">
                <Button
                  className="return"
                  large={true}
                  text="Wróć do listy case studies"
                  href="#case-studies"
                />
                <Button
                  className="accent"
                  large={true}
                  text="Sprawdź sprint"
                  newWindow={true}
                  href="https://ge.sprin.tech/"
                />
                <Button
                  className="accent"
                  large={true}
                  text="Przeczytaj raport"
                  newWindow={true}
                  href="https://sprin.tech/report/sprintech-raport-prostata.pdf"
                />
                <Button large={true} text="Co dalej?" href="#contact" />
              </div>
            </div>
          </Section>
        </>
      )}
      <Section id="contact" fullHeight={true}>
        <div className="card">
          <h1>Skontaktuj się z nami!</h1>
          <p>
            Jeśli chciałbyś spróbować sprintów w swojej firmie albo dowiedzieć się więcej o tym, jak
            sprinty mogą pomóc Twojemu biznesowi, skontaktuj się z nami!
          </p>
          <div className="work-steps">
            <IconBox
              className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={phoneIcon}
            >
              <h3>Telefonicznie:</h3>
              <p className="phone">
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
              </p>
            </IconBox>
            <IconBox
              className="case-study-details-icon-box"
              position={IconBox.POSITIONS.LEFT}
              iconPath={mailIcon}
            >
              <h3>Mailowo:</h3>
              <p className="phone">
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </p>
            </IconBox>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default App
