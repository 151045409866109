import React from 'react';
import clsx from 'clsx';

import "./IconBox.scss";

export default function IconBox({className, iconPath, children, position="top", ...other}) {
  return <div className={clsx("IconBox", className, position)} {...other}>
    <img src={iconPath} alt="icon" className="icon"/>
    <div className="content">
      {children}
    </div>
  </div>
}

IconBox.POSITIONS = {
  TOP: "top",
  LEFT: "left",
};