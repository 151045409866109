import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import Backend from "i18next-http-backend"

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "pl",
    // lng: "en",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      queryStringParams: { v: "1.0.3" },
      loadPath: "/about/locales/{{lng}}/{{ns}}.json",
    },
  })

export default i18n
